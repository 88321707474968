<template>
    <v-model-table
        v-bind="tableDataSource"
        :columns="columns"
        :query="query"
    />
</template>

<script>
export default {
    name: "UserLoginTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "jobModule",
            };
        },
        columns() {
            const tableColumns = {
                ip_address: {
                    label: "IP Address",
                    fullWidth: false,
                },
                login_at: {
                    label: "Login At",
                    fullWidth: false,
                },
            };

            return tableColumns;
        },
    },
};
</script>
